/* eslint-disable quotes */
exports.appConfig =
{
  "apiKey": "AIzaSyDsfIOukjKWAWHTvGO4tmo5Rp6cmmgo2Ho",
  "appId": "1:595436544419:web:f175f646ab83534551ba16",
  "authDomain": "schooldog-instance-dev.firebaseapp.com",
  "measurementId": "G-RJJVDH977V",
  "messagingSenderId": "595436544419",
  "project": "schooldog-instance-dev",
  "projectId": "schooldog-instance-dev",
  "showGaBranding": true,
  "storageBucket": "schooldog-instance-dev.appspot.com"
}
