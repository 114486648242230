<template>
  <v-dialog :value="value" @input="$emit('input', $event)" max-width="720px">
    <v-card>
      <v-card-title>
        Observation Data Explorer
        <v-spacer />
        <v-btn icon @click="$emit('input', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle v-if="observationsCount !== null">
        There are {{ observationsCount }} entries matching the selected filters.
      </v-card-subtitle>
      <v-container class="pt-0">
        <div v-if="user.can('download entry reports')">
          <v-btn v-if="observations.length" @click="generateCsvForWalk()" color="primary" text class="mb-1">
            <v-icon>mdi-file-excel-box</v-icon><span style="padding: 12px">Download CSV</span>
          </v-btn>
          <v-btn v-if="observations.length" @click="genereatePdfForWalk()" color="primary" text class="mb-1" :disabled="observationsCount !== null && observationsCount > 250">
            <v-icon>mdi-file-pdf-box</v-icon><span style="padding: 12px">Download PDF</span>
          </v-btn>
          <div v-if="observationsCount !== null && observationsCount > 250" class="text-body-1 font-italic mb-1 font-weight-light">
            You cannot generate a PDF report for more than 250 entries.
          </div>
        </div>
        <v-divider v-if="observations.length && user.can('download entry reports')" class="mb-2"></v-divider>
        <entry-card
          v-for="observation in observations"
          readonly
          :key="observation.id"
          :observation="observation"
          show-school
          show-walk
          class="mb-2" />
          <div class="d-flex fill-width justify-center">
            <v-btn
              v-if="hasMore"
              @click="fetchNextPage"
              class="mt-2"
              color="primary"
              text
              >
              Load More
            </v-btn>
          </div>
      </v-container>
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey darken-1" text @click="$emit('input', false)">Close</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="isGeneratingReport" persistent max-width="528px">
      <v-card>
        <v-card-title>
          Generating Report...
        </v-card-title>
        <v-card-text>
          <div>
            This might take a few seconds.
          </div>
          <div class="row justify-center pt-4">
            <v-progress-circular color="primary" indeterminate></v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isDownloadedCsvDialogVisible" max-width="528px">
      <v-card>
        <v-card-title>
          Your CSV Is Ready
        </v-card-title>
        <v-card-text>
          <div>
            Your generated CSV should have downloaded automatically. You can also use the link below to download.
          </div>
          <div class="row justify-center pt-4">
            <v-btn text @click="finishCsvDownload()" color="primary">
              <v-icon>mdi-download</v-icon><span style="padding: 12px">Download Generated CSV</span>
            </v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="isDownloadedCsvDialogVisible = false" color="primary">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isDownloadedPdfDialogVisible" max-width="528px">
      <v-card>
        <v-card-title>
          Your PDF Is Ready
        </v-card-title>
        <v-card-text>
          <div>
            Your generated PDF should have opened automatically. You can also use the link below to download. The link is only valid for 10 minutes after generation.
          </div>
          <div class="row justify-center pt-4">
            <v-btn text :href="reportUrl" target="_blank" color="primary">
              <v-icon>mdi-download</v-icon><span style="padding: 12px">Download Generated PDF</span>
            </v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="isDownloadedPdfDialogVisible = false" color="primary">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import Observation from './../../models/Observation'
import { collection, query, where, getDocs, limit, startAfter, orderBy } from 'firebase/firestore';
import { downloadCsv } from './../../services/DownloadCsvService'
import EntryCard from '../walks/EntryCard.vue';
import { mapState } from 'vuex';
export default {
  components: { EntryCard },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
    observationType: {
      type: String,
      required: true,
    },
    schoolId: {
      type: String,
    },
    observationCategoryId: {
      type: String,
      default: null,
    },
    observationItemId: {
      type: String,
      default: null,
    },
    observationLocation: {
      type: String,
      default: null,
    },
    walkType: {
      type: String,
      default: null,
    },
    userId: {
      type: String,
      default: null,
    },
    reportTitle: {
      type: String,
      default: null,
    },
    tag: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      observations: [],
      lastVisible: null,
      hasMore: false,
      reportCsvData: null,
      isDownloadedCsvDialogVisible: false,
      isGeneratingReport: false,
      isDownloadedPdfDialogVisible: false,
      observationsCount: null,
      reportUrl: null,
    }
  },
  computed: {
    ...mapState('app', [
      'user',
    ]),
  },
  methods: {
    getFetchDataConditions () {
      const conditions = []
      const restrictedSchoolPermissions = this.user.can('view data insights') ? null : (this.user.permissions['view data insights']?.forSchools ?? [])
      if (this.schoolId) {
        conditions.push([
          'schoolId',
          '==',
          this.schoolId,
        ])
      } else if (restrictedSchoolPermissions) {
        conditions.push([
          'schoolId',
          'in',
          restrictedSchoolPermissions,
        ])
      }
      if (this.observationType && this.observationType !== 'all') {
        conditions.push([
          'observationType',
          '==',
          this.observationType,
        ])
      }
      if (this.observationCategoryId) {
        conditions.push([
          'observationCategoryId',
          '==',
          this.observationCategoryId,
        ])
      }
      if (this.observationItemId) {
        conditions.push([
          'observationItemId',
          '==',
          this.observationItemId,
        ])
      }
      if (this.observationLocation) {
        conditions.push([
          'searchableLocation',
          '==',
          this.observationLocation.trim().toLowerCase(),
        ])
      }
      if (this.startDate) {
        conditions.push([
          'timestamp',
          '>=',
          new Date(`${this.startDate}T00:00:00`),
        ])
      }
      if (this.endDate) {
        conditions.push([
          'timestamp',
          '<=',
          new Date(`${this.endDate}T23:59:59`),
        ])
      }
      if (this.walkType) {
        conditions.push([
          'walkType',
          '==',
          this.walkType.toLowerCase(),
        ])
      }
      if (this.userId) {
        conditions.push([
          'reportedByUserId',
          '==',
          this.userId,
        ])
      }
      if (this.tag) {
        conditions.push([
          'searchableTags',
          'array-contains',
          this.tag.trim().toLowerCase(),
        ])
      }
      return conditions
    },
    async fetchObservationCount () {
      let q = collection(this.db, 'observations');

      const conditions = this.getFetchDataConditions();
      conditions.forEach(condition => {
        q = query(q, where(...condition));
      });

      try {
        const querySnapshot = await getDocs(q);
        this.observationsCount = querySnapshot.size;
      } catch (error) {
        console.error('Error fetching observations: ', error);
      }
    },
    async fetchObservations (isNextPage = false) {
      let q = collection(this.db, 'observations');

      const conditions = this.getFetchDataConditions();
      conditions.forEach(condition => {
        q = query(q, where(...condition));
      });

      if (isNextPage && this.lastVisible) {
        q = query(q, orderBy('timestamp'), startAfter(this.lastVisible), limit(20));
      } else {
        q = query(q, orderBy('timestamp'), limit(20));
      }

      try {
        const querySnapshot = await getDocs(q);

        if (querySnapshot.size < 20) {
          this.hasMore = false;
        } else {
          this.hasMore = true;
        }

        if (!isNextPage) {
          this.observations = [];
        }

        querySnapshot.forEach(doc => {
          const observation = new Observation(doc.id, doc.data());
          this.observations.push(observation);
        });

        this.lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      } catch (error) {
        console.error('Error fetching observations: ', error);
      }
    },
    async fetchNextPage() {
      await this.fetchObservations(true);
    },
    async generateCsvForWalk() {
      this.isGeneratingReport = true;
      try {
        const data = await this.runFunction('generateCsvFromObservationsQuery', {
          query: this.getFetchDataConditions(),
        })
        this.reportCsvData = data.csv
        this.finishCsvDownload()
        this.isDownloadedCsvDialogVisible = true
      } finally {
        this.isGeneratingReport = false
      }
    },
    async genereatePdfForWalk() {
      this.isGeneratingReport = true;

      try {
        const data = await this.runFunction('generateDataInsightsPdf', {
          query: this.getFetchDataConditions(),
          title: this.reportTitle,
        })
        this.reportUrl = data
        window.open(data, '_blank');
        this.isDownloadedPdfDialogVisible = true
      } finally {
        this.isGeneratingReport = false
      }
    },
    async finishCsvDownload() {
      const date = new Date()
      let title = this.reportTitle;
      if (this.startDate && this.endDate) {
        const startDate = this.$options.filters.formatDate(this.startDate, 'YYYY-MM-DD')
        const endDate = this.$options.filters.formatDate(this.endDate, 'YYYY-MM-DD')
        title = `${this.reportTitle} - ${startDate} to ${endDate}`
      }
      downloadCsv(this.reportCsvData, title)
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value) {
          this.fetchObservations()
          this.fetchObservationCount()
        }
      },
    },
  },
};
</script>

<style>
</style>